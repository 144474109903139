var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"4.5%"}},[(_vm.isLoading)?_c('div',[_c('OtherLoading')],1):_c('div',[_c('b-form',[_c('div',[_c('h1',{staticClass:"mr-sm-4 header-tablepage"},[_vm._v("Product Banner detail")])]),_c('div',{staticClass:"title-panel mt-3"},[_vm._v("Product Banner Info")]),_c('b-container',{staticClass:"no-gutters bg-white"},[_c('div',{staticClass:"py-3"},[_c('b-row',[_c('b-col',{staticClass:"order-1",attrs:{"md":"6"}},[_c('UploadFile',{attrs:{"textFloat":"Cover Image","placeholder":"Please choose file","format":"video","fileName":_vm.form.productbanner.image_url,"name":"thumbnail","text":"*Please upload only .png, .jpg image with ratio 2:1 and .mp4","isRequired":"","accept":_vm.acceptType,"v":_vm.$v.form.productbanner.image_url,"disabled":_vm.isDisable},on:{"onFileChange":_vm.onImageChange,"delete":_vm.deleteImage},model:{value:(_vm.form.productbanner.image_url),callback:function ($$v) {_vm.$set(_vm.form.productbanner, "image_url", $$v)},expression:"form.productbanner.image_url"}})],1),_c('b-col',{staticClass:"order-3 order-sm-2",attrs:{"md":"6"}},[_c('InputText',{attrs:{"textFloat":"Sort Order","placeholder":"Sort Order","type":"number","name":"sortOrder","disabled":_vm.isDisable},model:{value:(_vm.form.productbanner.sortOrder),callback:function ($$v) {_vm.$set(_vm.form.productbanner, "sortOrder", $$v)},expression:"form.productbanner.sortOrder"}})],1),_c('b-col',{staticClass:"order-2 order-sm-3",attrs:{"md":"6"}},[(_vm.showPreviewType === 0)?_c('div',{staticClass:"preview-box-image b-contain",style:({
                  'background-image': 'url(' + _vm.showPreview + ')',
                })},[(_vm.isLoadingImage)?_c('img',{staticClass:"loading",attrs:{"src":require("@/assets/icons/loading.svg"),"alt":"loading"}}):_vm._e(),(_vm.form.productbanner.image_url)?_c('font-awesome-icon',{staticClass:"text-white delete-icon pointer",attrs:{"icon":"times-circle","disabled":_vm.isDisable},on:{"click":_vm.deleteImage}}):_vm._e()],1):_c('div',{staticClass:"preview-box-video position-relative p-0 embed-responsive embed-responsive-16by9"},[_c('video',{ref:"videoRef",staticClass:"video-box",attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.showPreview,"type":"video/mp4"}})])])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"label text-nowrap"},[_vm._v(" Product Banner Type "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('InputSelect',{attrs:{"title":"","name":"product banner type","isRequired":"","options":_vm.typelist,"valueField":"id","textField":"redirect_type_name","noPleaseSelect":_vm.noPleaseSelect,"disabled":_vm.isDisable,"v":_vm.$v.form.productbanner.mobile_redirect_type_id},on:{"onDataChange":_vm.handleChangeBannerType},model:{value:(_vm.form.productbanner.mobile_redirect_type_id),callback:function ($$v) {_vm.$set(_vm.form.productbanner, "mobile_redirect_type_id", $$v)},expression:"form.productbanner.mobile_redirect_type_id"}})],1),(_vm.form.productbanner.mobile_redirect_type_id == 5)?_c('b-col',{attrs:{"cols":"6"}},[_c('InputText',{attrs:{"textFloat":"External URL","placeholder":"External URL","type":"text","name":"externalurl","disabled":_vm.isDisable},model:{value:(_vm.form.productbanner.redirect_id),callback:function ($$v) {_vm.$set(_vm.form.productbanner, "redirect_id", $$v)},expression:"form.productbanner.redirect_id"}})],1):_vm._e()],1),(
              _vm.form.productbanner.mobile_redirect_type_id == 1 ||
              _vm.form.productbanner.mobile_redirect_type_id == 2 ||
              _vm.form.productbanner.mobile_redirect_type_id == 3 ||
              _vm.form.productbanner.mobile_redirect_type_id == 4
            )?_c('div',{staticClass:"mt-3 panelborder"},[(_vm.form.productbanner.mobile_redirect_type_id == 1)?_c('div',{staticClass:"title-panel"},[_vm._v("Product Detail")]):_vm._e(),(_vm.form.productbanner.mobile_redirect_type_id == 2)?_c('div',{staticClass:"title-panel"},[_vm._v("Category")]):_vm._e(),(_vm.form.productbanner.mobile_redirect_type_id == 3)?_c('div',{staticClass:"title-panel"},[_vm._v("Subcategory")]):_vm._e(),(_vm.form.productbanner.mobile_redirect_type_id == 4)?_c('div',{staticClass:"title-panel"},[_vm._v("Monthly privilege")]):_vm._e(),_c('b-container',{staticClass:"no-gutters bg-white"},[_c('div',{staticClass:"py-3"},[_c('ProductList',{attrs:{"productList":_vm.productList,"rows":_vm.rows,"idList":_vm.IdList,"isBusy":_vm.isBusy,"disabled":_vm.isDisable},on:{"setRedirectID":_vm.setRedirectID,"handleSearchRedirectList":_vm.handleSearchRedirectList}})],1)])],1):_vm._e()],1)]),_c('div',{staticClass:"no-gutters bg-white mt-3 py-2 px-3"},[_c('b-form-checkbox',{staticClass:"radio-active",attrs:{"switch":"","size":"lg","value":1,"unchecked-value":0},model:{value:(_vm.form.productbanner.status),callback:function ($$v) {_vm.$set(_vm.form.productbanner, "status", $$v)},expression:"form.productbanner.status"}},[_c('span',{staticClass:"ml-2 main-label"},[_vm._v(_vm._s(_vm.form.productbanner.status ? "Active" : "Inactive"))])])],1),_c('FooterAction',{attrs:{"routePath":"/productbanner"},on:{"submit":function($event){return _vm.checkForm(0)}}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }