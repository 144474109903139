<template>
  <div>
    <b-row class="no-gutters mt-2 justify-content-end">
      <b-col md="6" sm="6" lg="5">
        <b-input-group>
          <b-form-input
            type="search"
            id="header-search-bar"
            class="search-bar"
            @keyup.enter="handleSearchRedirectList"
            v-model="filter.search"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <b-iconstack
              font-scale="2"
              type="submit"
              @click="handleSearchRedirectList"
            >
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
        </b-input-group>

        <!-- <b-row>
          <p class="font-weight-bold my-2 mx-3 search-text">Search:</p>
          <b-col md="6" class="mt-1 justify-content-start">
            <b-form-input
              @keyup="handleSearchRedirectList"
              placeholder="Name"
              v-model="filter.search"
            >
            </b-form-input>
          </b-col>
          <b-col md="2" class="px-0 justify-content-start">
            <b-iconstack font-scale="3" type="submit" @click="handleSearchRedirectList">
              <b-icon stacked icon="circle-fill" class="main-color"></b-icon>
              <b-icon stacked icon="search" scale="0.5" variant="white"></b-icon>
            </b-iconstack>
          </b-col>
        </b-row> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 w-100">
        <b-table
          responsive
          striped
          :fields="fields"
          :items="productList"
          :busy="isBusy"
          :per-page="filter.perPage"
          :current-page="filter.pageNo"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(id)="data">
            <b-form-checkbox
              size="lg"
              class="ml-3"
              :value="data.item.id"
              v-model="productSelectedList"
              @change="checkProductID(data.item.id)"
            ></b-form-checkbox>
          </template>
          <template v-slot:cell(image_url)="data">
            <div
              class="image"
              v-bind:style="{
                'background-image': 'url(' + data.item.image_url + ')',
              }"
            ></div>
          </template>
          <template v-slot:cell(name)="data">
            <span class="mb-0 nobreak two-lines">{{ data.item.name }}</span>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="no-gutters mt-2 justify-content-end">
      <b-col cols="12">
        <b-pagination
          v-model="filter.pageNo"
          :total-rows="rows"
          :per-page="filter.perPage"
          class="m-md-0"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  props: {
    productList: {
      required: true,
      type: Object | Array,
    },
    rows: {
      required: false,
      type: Number,
    },
    idList: {
      required: false,
      type: Array,
    },
    isBusy: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      productSelectedList: this.idList,
      fields: [
        {
          key: "id",
          label: "#",
        },
        {
          key: "image_url",
          label: "Thumbnail",
        },
        {
          key: "name",
          label: "Product Name",
          tdClass: "text-center",
        },
      ],
      filter: {
        perPage: 5,
        pageNo: 1,
        search: "",
        productIdList: [],
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },
  methods: {
    handleSearchRedirectList(e) {
      if (e.type === "keyup") {
        if (e.keyCode === 13) {
          this.$emit("handleSearchRedirectList", this.filter.search);
        }
      } else if (e.type === "click") {
        this.$emit("handleSearchRedirectList", this.filter.search);
      }
    },
    checkProductID(id) {
      Vue.nextTick(() => {
        this.$emit("setRedirectID", id);
      });
    },
  },
};
</script>

<style scoped>
.pagination{
  justify-content: center;
}
.image {
  width: 50%;
  height: 50%;
  padding-top: 42.9%;
  margin-left: 25%;
  background-position: center !important;
  background-size: cover;
  background-repeat: no-repeat;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
input#header-search-bar::placeholder {
  text-transform: capitalize;
}
</style>
