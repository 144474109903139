<template>
  <div style="padding-bottom: 4.5%">
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">Product Banner detail</h1>
        </div>
        <div class="title-panel mt-3">Product Banner Info</div>
        <b-container class="no-gutters bg-white">
          <div class="py-3">
            <b-row>
              <b-col md="6" class="order-1">
                <UploadFile
                  textFloat="Cover Image"
                  placeholder="Please choose file"
                  format="video"
                  :fileName="form.productbanner.image_url"
                  v-model="form.productbanner.image_url"
                  name="thumbnail"
                  text="*Please upload only .png, .jpg image with ratio 2:1 and .mp4"
                  isRequired
                  :accept="acceptType"
                  v-on:onFileChange="onImageChange"
                  v-on:delete="deleteImage"
                  :v="$v.form.productbanner.image_url"
                  :disabled="isDisable"
                />
              </b-col>
              <b-col md="6" class="order-3 order-sm-2">
                <InputText
                  textFloat="Sort Order"
                  placeholder="Sort Order"
                  type="number"
                  name="sortOrder"
                  v-model="form.productbanner.sortOrder"
                  :disabled="isDisable"
                />
              </b-col>
              <b-col md="6" class="order-2 order-sm-3">
                <div
                  v-if="showPreviewType === 0"
                  class="preview-box-image b-contain"
                  v-bind:style="{
                    'background-image': 'url(' + showPreview + ')',
                  }"
                >
                  <img
                    src="@/assets/icons/loading.svg"
                    class="loading"
                    alt="loading"
                    v-if="isLoadingImage"
                  />
                  <font-awesome-icon
                    icon="times-circle"
                    class="text-white delete-icon pointer"
                    v-if="form.productbanner.image_url"
                    @click="deleteImage"
                    :disabled="isDisable"
                  />
                </div>
                <div
                  class="
                    preview-box-video
                    position-relative
                    p-0
                    embed-responsive embed-responsive-16by9
                  "
                  v-else
                >
                  <video ref="videoRef" class="video-box" controls>
                    <source :src="showPreview" type="video/mp4" />
                  </video>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <div class="d-flex align-items-center">
                  <span class="label text-nowrap">
                    Product Banner Type <span class="text-danger">*</span></span
                  >
                </div>
                <InputSelect
                  title=""
                  name="product banner type"
                  isRequired
                  v-model="form.productbanner.mobile_redirect_type_id"
                  v-bind:options="typelist"
                  valueField="id"
                  textField="redirect_type_name"
                  :noPleaseSelect="noPleaseSelect"
                  @onDataChange="handleChangeBannerType"
                  :disabled="isDisable"
                  :v="$v.form.productbanner.mobile_redirect_type_id"
                />
              </b-col>
              <b-col
                cols="6"
                v-if="form.productbanner.mobile_redirect_type_id == 5"
              >
                <InputText
                  textFloat="External URL"
                  placeholder="External URL"
                  type="text"
                  name="externalurl"
                  :disabled="isDisable"
                  v-model="form.productbanner.redirect_id"
                />
              </b-col>
            </b-row>
            <div
              v-if="
                form.productbanner.mobile_redirect_type_id == 1 ||
                form.productbanner.mobile_redirect_type_id == 2 ||
                form.productbanner.mobile_redirect_type_id == 3 ||
                form.productbanner.mobile_redirect_type_id == 4
              "
              class="mt-3 panelborder"
            >
              <div class="title-panel" v-if="form.productbanner.mobile_redirect_type_id == 1">Product Detail</div>
              <div class="title-panel" v-if="form.productbanner.mobile_redirect_type_id == 2">Category</div>
              <div class="title-panel" v-if="form.productbanner.mobile_redirect_type_id == 3">Subcategory</div>
              <div class="title-panel" v-if="form.productbanner.mobile_redirect_type_id == 4">Monthly privilege</div>
              <b-container class="no-gutters bg-white">
                <div class="py-3">
                  <ProductList
                    :productList="productList"
                    :rows="rows"
                    :idList="IdList"
                    :isBusy="isBusy"
                    :disabled="isDisable"
                    v-on:setRedirectID="setRedirectID"
                    @handleSearchRedirectList="handleSearchRedirectList"
                  />
                </div>
              </b-container>
            </div>
          </div>
        </b-container>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="form.productbanner.status"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              form.productbanner.status ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>
        <FooterAction @submit="checkForm(0)" routePath="/productbanner" />
      </b-form>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import OtherLoading from "@/components/loading/OtherLoading";
import InputSelect from "@/components/inputs/InputSelect";
import UploadFile from "@/components/inputs/UploadFile";
import { required } from "vuelidate/lib/validators";
import ProductList from "@/components/product/ProductList";

export default {
  name: "ProductBannerDetails",
  components: {
    InputText,
    InputSelect,
    UploadFile,
    ProductList,
    OtherLoading
  },
  data() {
    return {
      id: this.$route.params.id,
      existId: "",
      isEdit: false,
      isLoading: false,
      isLoadingImage: false,
      isDisable: false,
      noPleaseSelect: false,
      isBusy: false,
      coverImgType: 1,
      ratioMatch: false,
      showVideo: "",
      showPreview: "",
      showPreviewType: 0,
      typelist: [],
      productList: [],
      acceptType: "image/jpeg, image/png, video/mp4",
      rows: 0,
      IdList: [],
      filter: {
        search: "",
      },
      form: {
        productbanner: {
          id: 0,
          image_url: "",
          Base64String: "",
          is_video: 0,
          mobile_redirect_type_id: 0,
          redirect_type_name: "",
          redirect_id: "",
          sortOrder: 0,
          status: 0,
        },
      },
    };
  },
  validations: {
    form: {
      productbanner: {
        image_url: { required },
        mobile_redirect_type_id: { required },
        status: { required },
      },
    },
  },
  created: async function () {
    if (this.id !== "0") {
      await this.getDatas();
    }
    await this.GetRedirectType();
  },
  methods: {
    getDatas: async function () {
      await this.$store.dispatch("ActionGetBannerByID", this.id);
      var data = await this.$store.state.moduleConnectAPI.stateGetBannerByID;
      if (data.result == 1) {
        this.form.productbanner = data.detail;
        this.showPreview = this.form.productbanner.image_url;
        this.IdList = [data.detail.redirect_id]
        this.handleChangeBannerType(
          this.form.productbanner.mobile_redirect_type_id
        );
        if (this.form.productbanner.is_video === 1) {
          this.$v.form.$reset();
          this.showPreviewType = 1;
        } else {
          this.showPreviewType = 0;
        }
      }
    },
    async GetRedirectType() {
      await this.$store.dispatch("ActionGetRedirectType", this.id);
      var data = await this.$store.state.moduleConnectAPI.stateGetRedirectType;
      if (data.result == 1) {
        this.typelist = data.detail;
      }
    },
    handleChangeBannerType(id) {
      this.form.productbanner.mobile_redirect_type_id = id;
      this.GetRedirect();
    },
    setRedirectID: async function (id) {
      this.form.productbanner.redirect_id = id;
    },
    GetRedirect: async function () {
      this.isBusy = true;
      var setData = {
        id: this.form.productbanner.mobile_redirect_type_id,
        name: this.filter.search,
      };
      await this.$store.dispatch("ActionGetRedirect", setData);
      var data = await this.$store.state.moduleConnectAPI.stateGetRedirect;
      if (data.result == 1) {
        this.productList = data.detail;
        this.rows = data.detail.length;
        this.isBusy = false;
      }
    },
    onImageChange(val) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        if (val.type === "video/mp4") {
          var url = await this.saveImagetoDb(reader.result, true);
          this.showPreviewType = 1;
          this.isLoadingImage = false;
          this.isDisable = false;
          this.showPreview = url;
          this.form.productbanner.image_url = url;
          this.form.productbanner.is_video = 1;
        } else {
          let img = new Image();
          img.onload = () => {
            var width = parseInt(img.width) / 2;
            var height = parseInt(img.height) / 1;
            if (parseInt(width) !== parseInt(height)) {
              this.$swal("Please upload image with ratio 2:1 !", {
                icon: "info",
              });
              this.ratioMatch = false;
              this.isLoadingImage = false;
              this.isDisable = false;
              this.deleteImage();
            } else {
              this.ratioMatch = true;
              this.setUpdataImage(reader.result);
            }
          };
          img.src = await reader.result;
        }
      };
    },
    async setUpdataImage(base64) {
      if (this.ratioMatch === true) {
        var url = await this.saveImagetoDb(base64, false);
        this.showPreviewType = 0;
        this.isLoadingImage = false;
        this.isDisable = false;
        this.showPreview = url;
        this.form.productbanner.image_url = url;
        this.form.productbanner.is_video = 0;
      }
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
    deleteImage() {
      this.form.productbanner.image_url = "";
      this.showPreview = null;
    },
    checkForm: async function (flag) {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.modalAlertShow = false;
      this.flag = flag;
      this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      let data = null;
      if (this.form.productbanner.status === true) {
        this.form.productbanner.status = 1;
      } else {
        this.form.productbanner.status = 0;
      }
      this.form.productbanner.sortOrder = parseInt(
        this.form.productbanner.sortOrder
      );
      this.form.productbanner.level_id = 2;
      if (this.id !== "0") {
        await this.$store.dispatch(
          "ActionUpdateBanner",
          this.form.productbanner
        );
        data = await this.$store.state.moduleConnectAPI.stateUpdateBanner;
        if (data.result == 1) {
          this.$swal("Update Success!", {
            icon: "success",
          }).then(function () {
            window.location.href = "/productbanner";
          });
        }
      } else {
        await this.$store.dispatch(
          "ActionCreateBanner",
          this.form.productbanner
        );
        data = await this.$store.state.moduleConnectAPI.stateCreateBanner;
        if (data.result == 1) {
          this.$swal("Create Success!", {
            icon: "success",
          }).then(function () {
            window.location.href = "/productbanner";
          });
        }
      }
      this.isDisable = false;
    },
    handleSearchRedirectList(searchName) {
      this.filter.search = searchName;
      this.GetRedirect();
    },
  },
};
</script>

<style scoped>
.preview-box-video::before {
  width: 100%;
  padding-top: 56.25%;
}
.preview-box-image {
  width: 100%;
  padding-bottom: 46.7%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  margin-bottom: 15px;
  position: relative;
}
.label {
  color: #212529;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.panelborder {
  border: 1px solid #d8dbe0;
}
</style>
